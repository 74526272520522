import React, { useState } from 'react';
import { Box, TextField, MenuItem, Select, InputLabel, FormControl, Button } from '@mui/material';
import { useWallet } from '../context/WalletContext';
import { getNFTSmartContract, getStakeSmartContract, getTokenSmartContract } from '../shared';
import { useLoader } from '../context/LoaderContext';
import useNotification from '../utilities/notificationUtils';
import { stakePoolContractConfig, tokenContractConfig } from '../config';
import { ethers } from 'ethers';
import { handleMetaMaskError } from '../utilities/handleMetaMaskError';

const NFT_COLLECTION_LINK = "https://sudoswap.xyz/#/browse/sanko/buy/0xc447597efc493e36ccca16707559fb7238d0367c";

interface AddStakeProps {
    onAddStake: (amount: number, lockDuration: string) => void;
}

const AddStake: React.FC<AddStakeProps> = ({ onAddStake }) => {
    const [stakeAmount, setStakeAmount] = useState<string>(''); // For entering stake amount
    const [lockDuration, setLockDuration] = useState<string>(''); // For selecting lock duration
    const { account } = useWallet();
    const { showLoader, hideLoader, setLoaderMessage } = useLoader();
    const { showError, showInfo, showSuccess } = useNotification();

    const checkSimCard = async () => {
        const nftContract = await getNFTSmartContract();
        try {
            const nftBalance = await nftContract.balanceOf(account);
            if (Number(nftBalance) <= 0) {
                window.location.href = NFT_COLLECTION_LINK;
                throw Error("You do not have a sim card!");
            }
        } catch (error) {
            throw error;
        }
    };

    const approveToken = async (amount: bigint) => {
        const tokenContract = await getTokenSmartContract();
        try {
            const tx = await tokenContract.approve(stakePoolContractConfig.contractAddress, amount);
            await tx.wait();
        } catch (error) {
            throw error;
        }
    };

    const handleAddStake = async () => {
        const stakeValue = parseFloat(stakeAmount);
        if (!isNaN(stakeValue) && stakeValue > 0 && lockDuration) {
            try {
                showLoader();
                setLoaderMessage("Checking for Smoove Mobile Sim Card");
                await checkSimCard();

                const stakeValueInUnits = ethers.parseUnits(stakeValue.toString(), tokenContractConfig.decimals);

                setLoaderMessage("Approve the transaction to continue");
                await approveToken(stakeValueInUnits);
                setLoaderMessage("Transaction approved, Creating Staked $SP Position");
                const contract = await getStakeSmartContract();
                const tx = await contract.stake(
                    stakeValueInUnits,
                    lockDuration
                );
                console.log('Transaction hash:', tx.hash);
                await tx.wait();
                showSuccess(`You have successfully staked ${stakeValue} ${tokenContractConfig.symbol}`);
                onAddStake(stakeValue, lockDuration);
                setStakeAmount('');
                setLockDuration('');
            } catch (error: any) {
                console.log(error);
                showError(handleMetaMaskError(error));
            } finally {
                setLoaderMessage("Loading...");
                hideLoader();
            }
        }
    };

    return (
        <>
            <TextField
                label="Enter Stake Amount"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                type="number"
                variant="outlined"
                fullWidth
                style={{ marginBottom: '10px' }}
            />

            {/* Select Lock Duration */}
            <FormControl fullWidth style={{ marginBottom: '10px' }}>
                <InputLabel>Lock Duration</InputLabel>
                <Select
                    value={lockDuration}
                    onChange={(e) => setLockDuration(e.target.value)}
                    label="Lock Duration"
                    MenuProps={{
                        PaperProps: {
                            style: {
                                backgroundColor: 'black',
                                color: 'white',
                            },
                        },
                    }}
                >
                    <MenuItem value={'2'}>2 Weeks</MenuItem>
                    {/* <MenuItem value={'4'}>1 Month</MenuItem> */}
                    {/* <MenuItem value={'12'}>3 Months (2x Rewards)</MenuItem>
                    <MenuItem value={'26'}>6 Months (4x Rewards)</MenuItem> */}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                color="primary"
                onClick={handleAddStake}
                sx={{ width: '100%' }}
                style={{ marginBottom: '10px' }}
            >
                Stake $SP
            </Button>
        </>
    );
};

export { AddStake };
